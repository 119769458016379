<template>
  <header class="navbar">
    <div class="navbar-brand">
      <router-link
        class="navbar-item"
        to="/"
      >
        <img
          class="logo"
          src="@/assets/barta_logo_vertical.png"
          alt="Barta"
        >
      </router-link>

      <div
        class="navbar-burger burger"
        data-target="navMenu"
        @click="toggleSidebar"
      >
        <span/>
        <span/>
        <span/>
      </div>
    </div>

    <div
      id="navMenu"
      class="navbar-menu"
      :class="{ 'is-active': sidebar }"
    >
      <div class="navbar-end">
        <div
          class="navbar-item has-dropdown is-hoverable"
          @mouseover="toggleDropdown(true)"
          @mouseleave="toggleDropdown(false)"
        >
          <a class="navbar-link">
            {{authenticationName}}
          </a>
          <div
            class="navbar-dropdown is-right"
            :style="{ display: showDropdown ? 'block' : 'none' }"
          >
            <router-link class="navbar-item" to="/mi-perfil">
              Mi Perfil
            </router-link>
            <a class="navbar-item" @click="signOut">
              Cerrar Sesión
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'TheHeader',
  data () {
    return {
      routeChange: false,
      showDropdown: false
    }
  },
  watch: {
    $route () {
      this.routeChange = true
      this.showDropdown = false
    }
  },
  computed: {
    ...mapState({
      authenticationName: state => state.authentication.email,
      sidebar: state => state.ui.sidebar
    })
  },
  methods: {
    ...mapMutations(['toggleSidebar', 'signOut']),
    toggleDropdown (payload) {
      if (this.showDropdown !== payload) this.routeChange = false
      if (!this.routeChange) this.showDropdown = payload
    }
  }
}
</script>

<style lang="scss" scoped>
  .navbar {
    background-color: #fdfdfd;
    min-height: 90px;
  }
  .navbar-brand {
    min-width: 250px;
  }

  img.logo {
    width: 130px;
    max-height: max-content;
  }

  a {
    font-size: 16px;
    color: var(--secondary-gray);
  }

  .is__flex {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .navbar-link:not(.is-arrowless) {
    padding-right: 3rem;
  }

  .navbar-link:not(.is-arrowless)::after {
    border-color: var(--primary-green);
  }

  .toggle-menu {
    cursor: pointer;
  }

  .navbar-menu .navbar-dropdown .navbar-item {
    display: flex;
    align-items: center;
  }

  .navbar-burger {
    height: inherit;
  }

  .navbar-dropdown {
    border-top: none;
    box-shadow: 0 13px 20px 0 rgba(0, 0, 0, 0.16);
    border-radius: 0;
  }
  .navbar-dropdown a:hover {
    color: var(--primary-green) !important;
  }

  a.navbar-item,
  .navbar-link {
    background-color: transparent !important;
    color: var(--secondary-gray);
  }

  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  .navbar-link.is-active,
  .navbar-burger:hover {
    background-color: transparent !important;
    color: #b751b5;
  }

  @media screen and (min-width: 1024px) {
    .navbar-burger {
      display: block;
    }
  }

  @media screen and (max-width: 1023px) {
    .navbar {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 1.5rem;
    }
    .navbar-burger {
      color: var(--primary-gray);
    }
    .navbar-menu {
      background-color: transparent;
      box-shadow: none;
    }
    .navbar-dropdown {
      box-shadow: none;
    }
    .navbar-link:not(.is-arrowless)::after {
      display: none;
    }
    .navbar-link:focus-within:not(.is-arrowless) {
      padding: 0.5rem 0.75rem;
    }
    .navbar-dropdown .navbar-item, .navbar-link {
      padding: 0.5rem 0.75rem;
    }
  }
</style>
